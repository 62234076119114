@import '@scss/functions';

.section__over-mij {
  background-color: var(--black);
  color: white;

  @include border(white, bottom-right);
  
  .image {
    position: absolute;
    bottom: 0;
    left: 0;
    mix-blend-mode: difference;
    object-fit: contain;
    object-position: bottom;
  }

  .text, .section__title {
    z-index: 1;
    display: block;
    position: relative;

    p {
      margin-bottom: s(2);
      // text-align: justify;
      // text-justify: inter-word;
    }
  }

  display: flex;
  align-items: top;

  .section__inner {

    .image {
      width: 100%;
    }
  }

  @include bp(md) {
    align-items: center;
    .section__inner {
      width: calc(100vw / 12 * 5);
      margin-right: calc(100vw / 12 * 1);

      .image {
        width: 50%;
      }
    }
  }

  @include bp(xl) {
    .section__inner {
      width: calc(100vw / 12 * 3);
      margin-right: calc(100vw / 12 * 2);

      .image {
        width: 65%;
      }
    }
  }

  @include bp(xxl) {
    .section__inner {
      padding-bottom: s(12);
      .image {
        width: 45%;
      }
    }
  }
}
@import '@scss/functions';

.section__contact {
  background-color: var(--black);

  &, a {
    color: white;
  }

  a {
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }

  .section__inner {

    .columns {
      gap: s(6);
      padding-bottom: s(6);
      display: flex;
      flex-direction: column;
    }

    .logo {
      filter: invert(1);
      height: s(16);
    }

    .socials {
      display: flex;
      gap: s(3);
    }

    .column {
    }

    @include bp(md) {
      padding-top: s(6);

      .logo {
        height: s(24);
      }

      .columns {
        justify-content: space-between;
        flex-direction: row;
        padding-bottom: s(12);

        .socials {
          // align-items: flex-end;
        }
      }
    }
  }
}
@import '@scss/functions';

.section__home {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;

  @include border(var(--black), bottom-left);

  img {
    height: 25vw;
    max-height: 272px;
    min-height: 200px;
  }
}

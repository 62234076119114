@import '@scss/functions';

$bg-width-lg: 610px;
$bg-width-sm: 124px;

.section__maak-afspraak {
  z-index: 1;
  color: white;
  padding: 0;
  background-color: white;
  display: flex;
  padding-bottom: s(6);

  .menu-helper {
    &-wrapper {
      display: block;
      pointer-events: none;
      position: absolute;
      top: -70px;
      min-height: calc(100vh + 70px);
    }

    width: $bg-width-sm;
    transform: translateX(calc(100vw - #{$bg-width-sm}));

    @include bp(md) {
      width: $bg-width-lg;
      transform: translateX(calc(100vw - #{$bg-width-lg} - 20px));
    }

    
    height: 70px;
    top: 0px;
    position: sticky;
    float: right;
    background-color: white;
    z-index: 2;
    background-color: #ffffff91;
    backdrop-filter: blur(4px);
    border-radius: 0 0 4px 4px;
    // background: linear-gradient(35deg, rgba(255, 255, 255, 0) 10%, rgb(255, 255, 255) 100%);
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
    z-index: -1;
    @include border(white, top-left);
  }

  .section__inner {
    width: calc(100% - #{s(6)});
    
    .booking-widget {
      width: 100%;
      background-color: #ffffffc9;
      backdrop-filter: blur(4px);
      border-radius: 16px;
      box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
    }
  }

  @include bp(md, down) {
    .section__title {
      width: 110%;
      margin: s(4) 0;
    }

    .booking-widget {
      height: 650px;
    }
  }

  @include bp(md) {
    align-items: flex-end;

    .section__inner {
      width: calc(100vw / 12 * 6);

      .booking-widget {
        height: 560px;
      }
    }
  }

  @include bp(xl) {
    .section__inner {
      width: calc(100vw / 12 * 4);
      // margin-right: calc(100vw / 12 * 0);
      margin-right: s(3);
    }
  }

  @include bp(xxl) {
    .section__inner {
      padding-bottom: s(12);
      
      .booking-widget {
        height: 700px;
      }
    }
  }
}
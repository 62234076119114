@import '@scss/functions';

.section {
  padding: s(3);
  position: relative;
  box-sizing: border-box;
  scroll-snap-align: start;

  @media (min-height: 920px) {
    min-height: 920px !important;
  }

  &__inner {
    max-width: 1280px;
    margin: 0 auto;
  }

  &.fullheight {
    min-height: 100vh;
  }

  h3 {
    font-weight: 700;
    margin-bottom: s(2);
  }

  &__title {
    @include bp(md, down) {
      font-size: s(4);
    }

    font-size: s(5);
    margin: s(3) 0;
    font-weight: 900;
  }
}
@function s($multiplier) {
  @return $multiplier * 8px;
}

$breakpoints: (
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1600px,
);

@mixin bp($breakpoint, $direction: up) {
  $width: map-get($breakpoints, $breakpoint);

  @if $width {
    @if $direction != up {
      // Use max-width and add 1px to handle discrepancies
      @media (max-width: #{ $width + 1px }) {
        @content;
      }
    } @else {
      // Use min-width
      @media (min-width: $width) {
        @content;
      }
    }
  } @else {
    @error "Breakpoint `#{$breakpoint}` is not defined.";
  }
}

@mixin border($color, $orientation) {
  border-#{$orientation}-radius: s(7);
  
  @include bp(md, down) {
    border-#{$orientation}-radius: s(4);
  }

  @if $orientation == top-right or $orientation == top-left {
    &::after {
      top: 0;
    }
  } @else {
    &::after {
      bottom: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    z-index: -2;
  }
}
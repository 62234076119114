@import '@scss/functions';

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  color: white;
  z-index: 1000;
  mix-blend-mode: difference;
  
  &.dark-background {
    // mix-blend-mode: normal;
  }

  @include bp(md) {
    padding: s(3) s(4);
  }

  .active {
    font-weight: 900;
  }

  &__toggle {
    cursor: pointer;
    position: fixed;
    top: s(3);
    right: s(4);
    color: white;
    
    @include bp(md) {
      display: none;
    }
  }

  &__list {
    display: inline-flex;
    gap: s(4);

      li:nth-child(4) a{
        background: white;
        padding: s(1.5);
        border-radius: s(1);
        color: var(--black);;
      }

    * {
      color: white;
    }

    @include bp(md, down) {
      opacity: 0;
      pointer-events: none;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.2rem;

      * {
        color: var(--black);
      }
    }
  }

  &.visible {
    mix-blend-mode: normal;
    inset: 0;
    padding: s(3) s(4);
    background-color: rgba(#F0E2E2,0.7);
    backdrop-filter: blur(5px);
    transition: all .3s ease-in-out;
    // transition: background-color .3s ease-in-out,  backdrop-filter .3s ease-in-out;
    
    .navigation__toggle {
      color: var(--black);
    }
    
    .navigation__list {
      opacity: 1;
      width: 100%;
      height: calc(100vh - s(12));
      pointer-events: all;
      align-items: center;
      justify-content: space-around;
      transition: opacity .3s ease-in-out;
      
      li:nth-child(4) a{
        background: var(--black);
        padding: s(1.5);
        border-radius: s(1);
        color: white;
      }
    }
  }
}